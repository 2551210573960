import Link from 'next/link'
import type { FunctionComponent, ReactNode } from 'react'
import { Button } from './Button'
import { Container } from './Container'
import styles from './ErrorLayout.module.sass'
import { LayoutGeneric } from './LayoutGeneric'

export interface ErrorLayoutProps {
	title: ReactNode
	description?: ReactNode
}

export const ErrorLayout: FunctionComponent<ErrorLayoutProps> = ({ title, description }) => {
	return (
		<LayoutGeneric>
			<Container>
				<div className={styles.wrapper}>
					<div className={styles.logo}>
						<Link href="/" className={styles.logo_link}>
							<svg
								width="92"
								height="20"
								viewBox="0 0 92 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<g clipPath="url(#clip0_8598_24369)">
									<path
										d="M29.2541 0C23.8493 0 19.7744 4.3087 19.7744 10.0146C19.7744 15.7205 23.8208 20 29.1829 20C33.3218 20 36.4581 17.981 38.0224 14.3087L33.8481 13.4016C32.5538 15.5962 30.8399 15.9107 29.1829 15.9107C26.7721 15.9107 24.916 14.1331 24.4182 11.3753V11.2363H38.5701V9.63427C38.3709 4.14047 34.3813 0 29.2541 0ZM24.5249 7.92247V7.77613C24.8102 6.7276 25.4244 5.8055 26.272 5.15323C27.1196 4.501 28.153 4.15523 29.2114 4.16973C30.2552 4.12607 31.2802 4.46443 32.1044 5.1247C32.9284 5.785 33.4986 6.72447 33.7129 7.77613V7.92247H24.5249Z"
										fill="currentColor"
									/>
									<path
										d="M9.62129 15.2386C8.78389 15.2367 7.96038 15.0164 7.22649 14.5979C6.49263 14.1794 5.87194 13.5762 5.42154 12.8437C4.97113 12.1112 4.70549 11.273 4.649 10.4061C4.59248 9.53923 4.74697 8.6715 5.09822 7.88277C5.44947 7.09403 5.98623 6.4096 6.65912 5.8924C7.33201 5.3752 8.11941 5.0418 8.94896 4.92293C9.77848 4.8041 10.6236 4.90353 11.4064 5.21217C12.1891 5.52077 12.8846 6.02867 13.4287 6.6891H18.7325C17.9763 4.453 16.4793 2.56817 14.5083 1.37068C12.5374 0.173185 10.2206 -0.259137 7.97109 0.150732C5.72151 0.5606 3.68531 1.78604 2.22551 3.6086C0.765717 5.43117 -0.0228027 7.7324 0.000502173 10.102C0.0238071 12.4717 0.857433 14.7557 2.35277 16.547C3.84811 18.3383 5.90798 19.5204 8.16515 19.8825C10.4223 20.2447 12.7301 19.7634 14.677 18.5244C16.624 17.2854 18.0836 15.3693 18.7957 13.1177H13.6465C13.1812 13.7722 12.575 14.3048 11.8761 14.673C11.1773 15.0413 10.4051 15.235 9.62129 15.2386Z"
										fill="currentColor"
									/>
									<path
										d="M47.2044 2.37452C46.7372 2.80073 46.3507 3.30684 46.0632 3.86883V0.133042C45.0367 0.137857 44.0392 0.4705 43.2171 1.08195C42.3601 1.75573 41.6729 2.61918 41.2099 3.60367C40.7473 4.58813 40.5217 5.66647 40.5514 6.7529V20C41.5496 19.9945 42.521 19.6784 43.3299 19.0959C44.1355 18.4852 44.7963 17.7054 45.2649 16.8117C45.7334 15.9181 45.9982 14.9327 46.0404 13.9256C46.0404 13.7463 46.0404 13.5595 46.0404 13.3727V12.1548C46.0991 10.9046 46.3731 9.67377 46.8516 8.51617C47.246 7.5718 47.886 6.74907 48.7064 6.13273C49.9981 5.2524 51.5426 4.81443 53.1066 4.885H54.1354L54.3982 0.125608C53.9887 0.0238889 53.566 -0.0164292 53.1442 0.00603749C52.0425 -0.0369963 50.9438 0.151715 49.9206 0.55984C48.897 0.967963 47.9714 1.58631 47.2044 2.37452Z"
										fill="currentColor"
									/>
									<path
										d="M85.6696 8.4345L82.226 7.43963C80.8087 7.05923 80.1001 6.39357 80.1001 5.41333C80.1001 4.2941 80.9927 3.56987 82.3675 3.56987C82.9468 3.54007 83.5213 3.69457 84.0129 4.01247C84.5046 4.33037 84.89 4.7963 85.1169 5.34747L89.5385 4.38917C88.5183 1.62399 85.8467 0 82.3675 0C78.2222 0 75.4375 2.40674 75.4375 5.99853C75.4375 8.69057 77.046 10.4901 80.2063 11.3606L83.8771 12.3921C84.9184 12.6774 85.443 13.2846 85.443 14.1917C85.443 15.0988 84.7344 16.2984 82.8496 16.2984C82.2636 16.3394 81.679 16.2044 81.1659 15.9098C80.6528 15.6151 80.2333 15.1733 79.9583 14.6379L75.1611 15.684C75.5862 17.0739 77.138 20 82.5518 20C87.1436 20 89.9992 17.6298 89.9992 13.8039C90.0348 11.0607 88.5677 9.2538 85.6696 8.4345Z"
										fill="currentColor"
									/>
									<path
										d="M64.29 0C62.3336 0 60.4207 0.58651 58.794 1.68532C57.167 2.78414 55.8992 4.3459 55.1506 6.17317C54.4018 8.00043 54.2059 10.0111 54.5874 11.9509C54.9693 13.8907 55.9114 15.6725 57.295 17.0711C58.6783 18.4696 60.4411 19.422 62.3599 19.8078C64.2791 20.1937 66.2681 19.9957 68.0755 19.2388C69.8832 18.4819 71.4284 17.2002 72.5152 15.5557C73.6024 13.9112 74.1824 11.9778 74.1824 10C74.1824 7.34783 73.1401 4.8043 71.2849 2.92893C69.4298 1.05357 66.9134 0 64.29 0ZM64.29 15.2218C63.2684 15.2218 62.2696 14.9156 61.4202 14.3418C60.5707 13.768 59.9086 12.9525 59.5175 11.9983C59.1264 11.0442 59.0242 9.9942 59.2234 8.98127C59.4229 7.96833 59.9148 7.0379 60.6373 6.30763C61.3598 5.57733 62.2801 5.08 63.2822 4.8785C64.2844 4.67703 65.3227 4.78047 66.2668 5.1757C67.2105 5.57093 68.0174 6.24017 68.5849 7.0989C69.1528 7.95763 69.4555 8.96723 69.4555 10C69.4555 11.3849 68.9114 12.7131 67.9426 13.6924C66.9738 14.6717 65.6601 15.2218 64.29 15.2218Z"
										fill="currentColor"
									/>
								</g>
								<defs>
									<clipPath id="clip0_8598_24369">
										<rect width="92" height="20" fill="white" />
									</clipPath>
								</defs>
							</svg>
						</Link>
					</div>
					<h2 className={styles.title}>{title}</h2>
					{description && <div className={styles.description}>{description}</div>}
					<Button type="link" href="/" fill="blue">
						Go back home
					</Button>
				</div>
			</Container>
		</LayoutGeneric>
	)
}
